import * as React from 'react'
import { useState, useEffect } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import MainLayoutPart from '../../components/MainLayoutPart'
import Form from 'react-bootstrap/Form'
import { StyledFormControl, StyledFormLabel, style } from './StyledContactForm'
import StyledButton from '../../styledComponents/StyledButton'
import SectionTitle from '../../components/SectionTitle'
import { OneColSection } from '../../components/OneColSection'
import TextField from './TextField'
import TextArea from './TextArea'
import CheckRadio from './CheckRadio'
import Switch from './Switch'
import Select from './Select'
const ContactForm = ({slice}) => {
  const [validated, setValidated] = useState(false)

   const [fieldValidity, setFieldValidity] = useState({}) // State für die Gültigkeit der Felder

   // Initialisiere fieldValidity beim ersten Rendern
   useEffect(() => {
     const initialValidity = {}
     slice.items.forEach((field) => {
       initialValidity[field.field_name] = !field.required // Setze Pflichtfelder initial auf ungültig, andere auf gültig
     })
     setFieldValidity(initialValidity)
    //  console.log('initialValidity:', initialValidity) // Ausgabe des Objekts
   }, [slice.items])

   const handleFieldValidation = (fieldName, isValid) => {
    //  console.log('Updating fieldValidity:', fieldName, isValid) // Überprüfe die Werte
     setFieldValidity((prevState) => ({ ...prevState, [fieldName]: isValid }))
   }

   const handleSubmit = async (event) => {
     event.preventDefault()
     const form = event.currentTarget
     // Setze validated auf true, um die Validierungs-Styles anzuzeigen
     setValidated(true)
     
     // Überprüfe die Gültigkeit aller Felder
     const isFormValid = Object.values(fieldValidity).every(
       (isValid) => isValid,
      )
      //  console.log('fieldValidity:', fieldValidity)
      if (form.checkValidity() === false ) {
        event.stopPropagation()
      return
    }
    
    try {
      const formData = new FormData(form)
      // Gib die Formulardaten in der Konsole aus
      for (var pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      const response = await fetch('../../../api/submit', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })

      if (response.ok) {
         window.location.href = '/contactsuccess'
        // console.log('Form submitted successfully')
      } else {
        console.error('Form submission failed')
      }
    } catch (error) {
       console.error('Form submission failed', error)
     }
   }

  return (
    <StaticQuery
      query={graphql`
        {
          allPrismicPage {
            nodes {
              data {
                body {
                  ... on PrismicPageDataBodyKontaktformular {
                    primary {
                      submitt_button_text
                      submitted_text {
                        raw
                      }
                      form_instructions {
                        raw
                      }
                      form_title {
                        raw
                      }
                    }
                    items {
                      field_name
                      field_type
                      invalid_feedback_text
                      required
                      options
                    }
                    slice_type
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        // console.log('ContactForm data', data)
        function findeNichtLeeresObjekt(data) {
          const nodes = data.allPrismicPage.nodes
          // console.log('nodes', nodes)
          for (let i = 0; i < nodes.length; i++) {
            const body = nodes[i].data.body
            // console.log('body', body)
            for (let j = 0; j < body.length; j++) {
              const objekt = body[j]
              if (Object.keys(objekt).length > 0) {
                return objekt // Gib das erste gefundene nicht-leere Objekt zurück
              }
            }
          }
          return null // Gib null zurück, wenn kein nicht-leeres Objekt gefunden wurde
        }

        const slice = findeNichtLeeresObjekt(data)
        // console.log('kontaktformular', kontaktformular)

        // const slice = data.prismicPage.data.body[1]
        console.log('ContactForm slice', slice)
        /*         const formTitle = slice.primary.form_title.raw
        const formInstructions = slice.primary.form_instructions.raw */

        return (
          <>
            {/*             {!formTitle ? null : formTitle[0].text !== '' ? (
              <SectionTitle title={formTitle} />
            ) : null}
            {formInstructions ? (
              <OneColSection content={formInstructions} />
            ) : null} */}
            <MainLayoutPart
              content={
                <Form
                  name="contact"
                  method="POST"
                  data-netlify-honeypot="bot-field"
                  data-netlify="true"
                  lang="de"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  <p hidden>
                    <label>
                      Dont fill this out: <input name="bot-field" />
                    </label>
                  </p>
                  <input type="hidden" name="form-name" value="contact" />
                  {slice.items.map((field, i) => {
                    switch (field.field_type) {
                      case 'text':
                        return (
                          <TextField
                            key={i}
                            field={field}
                            onValidationChange={(isValid) =>
                              handleFieldValidation(field.field_name, isValid)
                            }
                          />
                        )
                      case 'textarea':
                        return <TextArea field={field} i={i} />
                      case 'email':
                        return (
                          <TextField
                            field={field}
                            i={i}
                            onValidationChange={(isValid) =>
                              handleFieldValidation(field.field_name, isValid)
                            }
                          />
                        )
                      case 'tel':
                        return (
                          <TextField
                            field={field}
                            i={i}
                            onValidationChange={(isValid) =>
                              handleFieldValidation(field.field_name, isValid)
                            }
                          />
                        )
                      case 'select':
                        return (
                          <Select
                            field={field}
                            onValidationChange={(isValid) =>
                              handleFieldValidation(field.field_name, isValid)
                            }
                            i={i}
                          />
                        )
                      case 'checkbox':
                        return (
                          <CheckRadio
                            field={field}
                            i={i}
                            type="checkbox"
                            onValidationChange={(isValid) =>
                              handleFieldValidation(field.field_name, isValid)
                            }
                          />
                        )
                      case 'radio':
                        return <CheckRadio field={field} i={i} type="radio" />
                      case 'switch':
                        return <Switch field={field} i={i} />
                      default:
                        return null
                    }
                  })}
                  <StyledButton variant="outline-danger" type="submit">
                    {slice.primary.submitt_button_text}
                  </StyledButton>
                </Form>
              }
            />
          </>
        )
      }}
    />
  )
}

export { ContactForm }
