import React from 'react'
import { StyledFormLabel, style, StyledFormGroup } from './StyledContactForm'
import Form from 'react-bootstrap/Form'


function TextArea({ field, i }) {
return (
  <StyledFormGroup key={i}>
    <StyledFormLabel htmlFor={field.field_name}>
      {field.field_name} {field.required === true ? ' *' : null}
    </StyledFormLabel>
    <Form.Control
      name="message"
      rows={3}
      required={field.required === true}
      style={style}
    />
    <Form.Control.Feedback type="invalid">
      {field.invalid_feedback_text}
    </Form.Control.Feedback>
  </StyledFormGroup>
)
}

export default TextArea