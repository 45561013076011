import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { StyledFormControl, StyledFormLabel, StyledFormGroup } from './StyledContactForm'

function Select({ field, onValidationChange }) {
    //  console.log('required', field.required)

  const [validated, setValidated] = useState(false)

  const options = field.options.split(', ')

  const handleChange = (event) => {
    const isValid = event.target.value !== '1'
    // console.log('isValid in Select:', isValid) // Debug-Ausgabe
    setValidated(isValid)
    onValidationChange(isValid) // Rufe die Callback-Funktion auf
    console.log('Ausgewählter Wert:', event.target.value) 
  }

  return (
    <StyledFormGroup>
      <StyledFormLabel htmlFor={field.field_name}>
        {field.field_name} {field.required === true ? ' *' : null}
      </StyledFormLabel>
      <Form.Select
        name={field.field_name} // Füge das name-Attribut hinzu
        aria-label={field.field_name}
        onChange={handleChange}
        required={field.required}
        // isInvalid={!validated} // nur wenn required = true
      >
        <option value="1">Bitte wählen Sie eine Option</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        Bitte wählen Sie eine Option aus.
      </Form.Control.Feedback>
    </StyledFormGroup>
  )
}

export default Select
