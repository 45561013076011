import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { StyledFormGroup } from './StyledContactForm'

const CheckRadio = ({ field, onValidationChange }) => {
const [value, setValue] = useState('')

const handleChange = (event) => {
  setValue(event.target.value)
  onValidationChange(event.target.checked) // Gültigkeit prüfen und Callback aufrufen
}
  const type = field.field_type

  return (
    <StyledFormGroup>
      <Form.Check // prettier-ignore
        id={field.field_name}
        type={type}
        name={field.field_name} // Füge das name-Attribut hinzu
        label={field.field_name}
        required={field.required === true}
        onChange={handleChange} // onChange Prop hinzufügen
      />
    </StyledFormGroup>
  )
}

export default CheckRadio
